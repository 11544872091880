import mapboxgl from 'mapbox-gl';
import React, { SetStateAction, useCallback, useState, Dispatch } from 'react';
import {default as MapGL, Source, Layer, NavigationControl} from 'react-map-gl';

import {trailStyle, trailLabelStyle, selectedTrailLayer} from '../mapStyles';
import {MapStyle} from './MapStyleSelect'

// interface MapViewport {
//     longitude: number
//     latitude: number
//     zoom: number
//     pitch: number
//     bearing: number
// }

interface MapProps {
    mapStyle: MapStyle
    trails: any | undefined
    selectedFeatureId: string
    onFeatureSelect: Dispatch<SetStateAction<string>>
    // viewport: MapViewport
    // onViewportChange: Dispatch<SetStateAction<MapViewport>>
}

function Map({mapStyle, trails, selectedFeatureId, onFeatureSelect/*, viewport, onViewportChange*/}: MapProps) {
    const [cursor, setCursor] = useState<string>('auto');

    const onMouseEnter = useCallback(() => setCursor('pointer'), []);
    const onMouseLeave = useCallback(() => setCursor('auto'), []);
    const handleClick = useCallback((e: mapboxgl.MapLayerMouseEvent) => {
        const feature = e.features && e.features[0];
        onFeatureSelect(feature?.properties?.id);
    }, [onFeatureSelect]);
    // const handleHover = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    //     const {
    //         features,
    //         point: {x, y}
    //       } = event;
    //       const hoveredFeature = features && features[0];
    //       console.log(hoveredFeature);
    // }, []);

    return (
        <MapGL
            //{...viewport}
            //onMove={e => { console.log(e.viewState); onViewportChange(e.viewState)}}
            initialViewState={{
                // longitude: -114.1267253,
                // latitude: 48.660261,
                longitude: -91.4568,
                latitude: 47.9862,
                zoom: 10,
                pitch: 0,
                bearing: 0
            }}
            style={{width: "100%", height: "100vh"}}
            cursor={cursor}
            mapStyle={(mapStyle === MapStyle.Terrain) ? "http://localhost:8080/styles/basic-preview/style.json" : "mapbox://styles/mapbox/satellite-v9"}
            //terrain={{source: 'mapbox-dem', exaggeration: 1.5}}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            interactiveLayerIds={trails ? ["trails"] : []}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={handleClick}
        >
            <NavigationControl
                visualizePitch={true}
            />
            {/* <Source
                id="mapbox-dem"
                type="raster-dem"
                url="mapbox://mapbox.mapbox-terrain-dem-v1"
                url="mapbox://mapbox.mapbox-terrain-dem-v1"
                tileSize={512}
                maxzoom={14}
            /> */}
            {/* <Source
                id="mapbox-dem"
                type="vector"
                tiles={["http://localhost:8080/data/v3/{z}/{x}/{y}.pbf"]}
                //tileSize={512}
                maxzoom={14}
            /> */}
            {trails &&
                <Source
                    id="trails"
                    type="geojson"
                    data={trails}
                >
                    <Layer {...trailStyle} />
                    <Layer {...trailLabelStyle} />
                    <Layer {...selectedTrailLayer(selectedFeatureId)} /*useMemo ?*/ />
                </Source>
            }
        </MapGL>
    );
}

export default Map;
