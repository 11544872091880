import {LineLayer, SymbolLayer} from 'react-map-gl';

const trailDashArray = [
    1,
    2
];


export const trailStyle: LineLayer = {
    "id": "trails",
    "type": "line",
    "source": "trails",
    "interactive": true,
    "minzoom": 7.5,
    "layout": {
        "line-cap": "round",
        "line-join": "round"
    },
    "paint": {
        "line-color": "hsl(0, 100%, 19%)",
        "line-width": {
            "base": 1.5,
            "stops": [
                [
                    7,
                    0.75
                ],
                [
                    10,
                    1
                ],
                [
                    14,
                    2
                ]
            ]
        },
        "line-dasharray": trailDashArray
    }
}

export const trailLabelStyle: SymbolLayer = {
    "id":"trail-label",
    "source":"trails",
    "type":"symbol",
    "minzoom": 9,
    "layout": {
        "text-size": {
            "base": 10,
            "stops": [
                [
                    9,
                    10
                ],
                [
                    20,
                    14
                ]
            ]
        },
        // "text-max-angle": 40,
        // "symbol-spacing": 250,
        "text-font": [
            "Roboto Regular",
            "Arial Unicode MS Regular"
        ],
        "symbol-placement": "line",
        // "text-padding": 1,
        // "text-rotation-alignment": "map",
        // "text-field": "{TRLLABEL}",
        "text-field": "{name}",
        "text-letter-spacing": 0.01
    },
    "paint": {
        "text-color": "hsl(0, 0%, 0%)",
        "text-halo-color": "hsl(0, 0%, 100%)",
        "text-halo-width": 1
    }
};

export const selectedTrailLayer = (featureId: string): LineLayer => {
    return {
        ...trailStyle,
        id: 'selected-trail',
        paint: {
            ...trailStyle.paint,
            "line-color": "rgb(255, 0, 0)",
        },
        filter: ["in", "id", featureId || '']
    }

}
