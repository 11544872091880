import React, { useState } from 'react';
import './App.css';
import { useQuery, gql } from '@apollo/client';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

import AppBar from '@mui/material/AppBar'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Toolbar from '@mui/material/Toolbar';


import Map from './components/Map';
import MapStyleSelect, {MapStyle} from './components/MapStyleSelect';
import Sidebar from './components/Sidebar';

// see https://github.com/mapbox/mapbox-gl-js/issues/10173 - solves build error
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;




const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
});

const GET_TRAIL_SEGMENTS_GEOJSON = gql`
query GetTrailSegmentGeojson {
  trailSegmentsGeojson {
    type
    features {
      type
      properties {
        id
        length
      }
      geometry {
        type
        coordinates
      }
    }
  }
}
`;

function App() {
    const [mapStyle, setMapStyle] = useState(MapStyle.Terrain);
    // const [trails, setTrails] = useState();
    const [selectedFeatureId, setSelectedFeatureId] = useState<string>('');
    const { error, data } = useQuery(GET_TRAIL_SEGMENTS_GEOJSON);

    if (error) return <p>Error : {error.message}</p>;


    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        variant="h1"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            fontSize: '1.5rem',
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            color: 'inherit',
                            textDecoration: 'none',
                            flexGrow: 1
                        }}
                    >
                        glacier.rocks
                    </Typography>

                    <MapStyleSelect
                        selectedStyle={mapStyle}
                        selectStyle={setMapStyle}
                    />
                </Toolbar>
            </AppBar>
            <Grid container component="main" sx={{ height: '100vh'}} >
                <Grid item sm={12} md={9}>
                    <Map
                        mapStyle={mapStyle}
                        trails={data?.trailSegmentsGeojson}
                        onFeatureSelect={setSelectedFeatureId}
                        selectedFeatureId={selectedFeatureId}
                        // viewport={mapViewport}
                        // onViewportChange={setViewport}
                    />
                </Grid>
                <Grid item sm={false} md={3} /*TODO how to show when responsive*/>
                    <Sidebar
                        selectedFeatureId={selectedFeatureId}
                    />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default App;
