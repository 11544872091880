import React, { Dispatch, SetStateAction } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import MapIcon from '@mui/icons-material/Map';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';

// this hsould probably be stored somewhere else?
export enum MapStyle {
    Terrain,
    Satellite,
}

interface MapStyleSelectProps {
    selectedStyle: MapStyle,
    selectStyle: Dispatch<SetStateAction<MapStyle>>
}

function MapStyleSelect({selectedStyle, selectStyle}: MapStyleSelectProps) {
    function handleClick(e: any, style: MapStyle) {
        e.preventDefault();
        selectStyle(style);
    }

    return (
        <ButtonGroup size="small">
            <Button
                variant={(selectedStyle === MapStyle.Terrain) ? "contained" : undefined}
                onClick={(e) => handleClick(e, MapStyle.Terrain)}
            >
                <MapIcon />
            </Button>
            <Button
                variant={(selectedStyle === MapStyle.Satellite) ? "contained" : undefined}
                onClick={(e) => handleClick(e, MapStyle.Satellite)}
            >
                <SatelliteAltIcon />
            </Button>
        </ButtonGroup>
    );
}

export default MapStyleSelect;
