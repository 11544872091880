import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useQuery, gql } from '@apollo/client';

const GET_TRAIL_SEGMENT = gql`
query GetTrailSegment($id: String!) {
    trailSegment(id: $id) {
        id
        length
        trail {
            id
            name
        }
    }
}
`

interface SidebarProps {
    selectedFeatureId: string
}

export default function Sidebar({selectedFeatureId}: SidebarProps) {
    const { loading, error, data: {trailSegment} = {}} = useQuery(GET_TRAIL_SEGMENT, {
        variables: { id: selectedFeatureId },
        skip: !selectedFeatureId,
    });
    if( ! selectedFeatureId) return null;
    if(error) return <p>Error : {error.message}</p>;
    if(loading) return <p>Loading...</p>;

    return (
        <Box sx={{"padding": "12px"}}>
            <Typography
                variant="h2"
                fontSize="1.3rem"
            >
                {trailSegment?.trail.name}
            </Typography>
            <Typography variant="body1">
                Length: {trailSegment?.length}
            </Typography>
        </Box>
    );
}
